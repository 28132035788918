import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {PayService} from '../pay.service';
import {PaymentMethods} from '../interfaces/payment-methods.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  checked = 'us';
  type = 'currency';
  key = '';
  formData = null;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private payService: PayService
  ) {
  }

  ngOnInit() {
    this.type = this.data.type;
    this.formData = this.data.data;
    this.key = this.data.key;
    const newWindow = window.open();
    if (this.type === 'pay') {
      this.payService.leaveTip(this.formData)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.dialogRef.close();
          newWindow.location.href = res.result.payment.formUrl;
          // window.open(res.result.payment.formUrl);
          // this.router.navigate(['/payment/' + res.result.transactionId]);
        }, err => this.dialogRef.close('err'));
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close();
  }

}
