// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // backendUrl: 'https://localhost:3003/api',
    // imageUrl: 'https://localhost:3003/api',

    backendUrl: 'https://dev-server.tips.am/api',
    imageUrl: 'https://dev-server.tips.am',

    // backendUrl: 'https://api.tips.am/api',
    // imageUrl: 'https://api.tips.am',

    idramCheckURL: 'https://dev-server.tips.am/api/tips/idram/result',
    idramFailURL: 'http://localhost:4200/payment',
    idramSuccessURL: 'http://localhost:4200/payment',
    inecopayQRURL: 'https://inecopayprelive.inecobank.am/sharing',
    stripePublicKey: 'pk_test_51NkWCtA6BLKsblCiEhW4B8S6a1iZc2ANJJvkpXkmDaJCwBsQMYBbAzuD5QUFVMlmQhC9E96DWwmeAWMck5dAmF3h00MgpNsKlZ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
