import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainFormComponent} from './main-form/main-form.component';
import {RestaurantComponent} from './restaurant/restaurant.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ThanksComponent} from './thanks/thanks.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'tip/:restaurantId',
        component: RestaurantComponent
    },
    {
        path: 'tip/:restaurantId/:id',
        component: MainFormComponent
    },
    {
        path: 'payment',
        component: ThanksComponent
    },
    {
        path: 'ameriatransactionstate',
        component: ThanksComponent
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
