export enum PaymentMethods {
  CARD = 'EVOCA',
  IDRAM = 'IDRAM',
  YOOMONEY = 'YOOMONEY',
  YOOCARD = 'YOOCARD',
  SBERBANK = 'SBERBANK',
  TINKOFFBANK = 'TINKOFFBANK',
  SBP = 'SBP',
  INECOPAY = 'INECOPAY',
  STRIPE = 'STRIPE',
  AMERIA = 'AMERIA',
}
