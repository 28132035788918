<div class="container">
  <div class="content-wrap">
    <div *ngIf="transaction">
      <div class="head">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14.78 7.7L9.11 13.37C8.97 13.51 8.78 13.59 8.58 13.59C8.38 13.59 8.19 13.51 8.05 13.37L5.22 10.54C4.93 10.25 4.93 9.77 5.22 9.48C5.51 9.19 5.99 9.19 6.28 9.48L8.58 11.78L13.72 6.64C14.01 6.35 14.49 6.35 14.78 6.64C15.07 6.93 15.07 7.4 14.78 7.7Z"
            fill="#60D25E"/>
        </svg>
        <p class="thanks"><b>{{'thanks_for_tip' | translate}},</b> {{'your_tip_was_sent_to' | translate}}</p>
      </div>
      <div class="card" id="check-section">
        <div class="card-title">
          <p class="card-title-name">{{transaction.restaurant.name}}</p>
          <p class="card-title-rating" *ngIf="transaction.restaurant.rating">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1398_939)">
                <path
                  d="M10.3341 4.79312L7.72898 6.68575L8.72415 9.74818C8.778 9.91399 8.71883 10.0955 8.57804 10.1982C8.50725 10.2494 8.42447 10.2749 8.3413 10.2749C8.25827 10.2749 8.17524 10.2493 8.10499 10.1982L5.50005 8.3054L2.89525 10.1979C2.75394 10.3005 2.563 10.3005 2.42199 10.1979C2.2813 10.0955 2.22206 9.91397 2.27598 9.74818L3.27088 6.68561L0.666071 4.79312C0.525037 4.69063 0.465796 4.50914 0.519715 4.34328C0.57377 4.17744 0.728446 4.06523 0.902607 4.06523H4.12242L5.11732 1.00302C5.17124 0.837205 5.3258 0.725037 5.50021 0.725037C5.67462 0.725037 5.82891 0.837205 5.88276 1.00302L6.87766 4.06523H10.0977C10.2718 4.06523 10.4264 4.17746 10.4806 4.34328C10.534 4.50914 10.4747 4.69063 10.3341 4.79312Z"
                  fill="#4D4D4D"/>
              </g>
              <defs>
                <clipPath id="clip0_1398_939">
                  <rect width="10" height="10" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <span>
            {{ transaction.restaurant.rating }}
          </span>
          </p>
        </div>

        <div class="card-content">
            <div class="card-content-item">
                <span>{{'ID' | translate}}</span>
                <span>{{transaction.orderNumber}}</span>
            </div>
          <div class="card-content-item">
            <span>{{'tip' | translate}}</span>
            <span>֏ {{transaction.amount}}</span>
          </div>
          <div class="card-content-item">
            <span>{{'transaction' | translate}}</span>
            <span>֏ {{transaction.fee}}</span>
          </div>
          <div class="card-content-item">
            <span>{{'total' | translate}}</span>
            <span>֏ {{transaction.amount + transaction.fee}}</span>
          </div>
        </div>

        <div class="card-footer">
          <div *ngIf="transaction.review.grade">
            <ng-rating-bar [colorActive]="'#60d25e'" [colorDefault]="'#E2E2E2'"
                           [styles]="{ fontSize: '14px', margin:'0px'}"
                           [value]="transaction.review.grade" [ratingCount]="5" [disabled]="true" class="start-wrap"
                           style="width: 100%"></ng-rating-bar>
          </div>
        </div>
      </div>
      <div class="download_check">
        <button (click)="toggleCheckFormCollapse()" [ngClass]="{'visible': visibleCheckForm}"
                class="download_check_btn">{{'save_the_check' | translate}}
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.19168 6.5896L8.09168 11.5042C8.15002 11.5625 8.21321 11.6039 8.28127 11.6284C8.34932 11.6529 8.42224 11.665 8.50002 11.6646C8.57779 11.6646 8.65071 11.6524 8.71877 11.6279C8.78682 11.6034 8.85002 11.5621 8.90835 11.5042L13.8229 6.5896C13.959 6.45349 14.0271 6.28335 14.0271 6.07919C14.0271 5.87502 13.9542 5.70002 13.8083 5.55419C13.6625 5.40835 13.4924 5.33544 13.2979 5.33544C13.1035 5.33544 12.9333 5.40835 12.7875 5.55419L8.50002 9.84169L4.21252 5.55419C4.07641 5.41808 3.9086 5.35002 3.7091 5.35002C3.5096 5.35002 3.33713 5.42294 3.19168 5.56877C3.04585 5.7146 2.97293 5.88474 2.97293 6.07919C2.97293 6.27363 3.04585 6.44377 3.19168 6.5896Z"
              fill="black"/>
          </svg>
        </button>

        <div class="download_check_collapse" [ngClass]="{'visible': visibleCheckForm}">
          <form [formGroup]="checkSendForm" (ngSubmit)="checkSend()">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" name="email" class="form-control" required formControlName="email">
              <div
                *ngIf="checkSendForm.get('email').invalid && (checkSendForm.get('email').dirty || checkSendForm.get('email').touched)"
                class="text-danger">
                <div *ngIf="checkSendForm.get('email').errors.required">Email {{'is_required' | translate}}</div>
                <div *ngIf="checkSendForm.get('email').errors.email">Email {{'is_invalid' | translate}}</div>
              </div>
            </div>
            <p class="success_message" *ngIf="visibleCheckSendSuccessMessage">{{'check_send_suc' | translate}}</p>

            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve" width="30" height="30"  *ngIf="visibleCheckSendPending">
                <path fill="#60D25E" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite" />
              </path>
            </svg>
            <button type="submit" class="btn-submit"
                    [disabled]="checkSendForm.invalid">{{'check_send' | translate}}</button>
          </form>
          <a class="download_check_btn" (click)="downloadPdf()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.76672 9.73328L9.90006 11.8666L12.0334 9.73328" stroke="black" stroke-width="1.5"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.90002 3.33337V11.8084" stroke="black" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M16.6667 10.15C16.6667 13.8334 14.1667 16.8167 10 16.8167C5.83337 16.8167 3.33337 13.8334 3.33337 10.15"
                stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
            </svg>
            {{'check_save_pdf' | translate}}</a>
        </div>
      </div>
    </div>

    <div *ngIf="paymentError && !paymentErrorNotFound" class="error">
      <svg class="error_svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM28.6 28.6C28.415 28.7854 28.1952 28.9325 27.9532 29.0329C27.7113 29.1332 27.4519 29.1849 27.19 29.1849C26.9281 29.1849 26.6687 29.1332 26.4268 29.0329C26.1848 28.9325 25.965 28.7854 25.78 28.6L20 22.82L14.22 28.6C13.846 28.974 13.3389 29.184 12.81 29.184C12.2811 29.184 11.774 28.974 11.4 28.6C11.026 28.226 10.816 27.7189 10.816 27.19C10.816 26.9281 10.8675 26.6688 10.9677 26.4269C11.068 26.185 11.2148 25.9652 11.4 25.78L17.18 20L11.4 14.22C11.026 13.846 10.816 13.3389 10.816 12.81C10.816 12.2811 11.026 11.774 11.4 11.4C11.774 11.026 12.2811 10.816 12.81 10.816C13.3389 10.816 13.846 11.026 14.22 11.4L20 17.18L25.78 11.4C25.9652 11.2148 26.185 11.068 26.4269 10.9677C26.6688 10.8675 26.9281 10.816 27.19 10.816C27.4519 10.816 27.7112 10.8675 27.9531 10.9677C28.195 11.068 28.4148 11.2148 28.6 11.4C28.7852 11.5852 28.932 11.805 29.0323 12.0469C29.1325 12.2888 29.184 12.5481 29.184 12.81C29.184 13.0719 29.1325 13.3312 29.0323 13.5731C28.932 13.815 28.7852 14.0348 28.6 14.22L22.82 20L28.6 25.78C29.36 26.54 29.36 27.82 28.6 28.6Z"
          fill="#D82020"/>
      </svg>
      <p class="error_message">{{'payment_error' | translate}}</p>
      <div class="error_causes">
        <button (click)="toggleErrorCausesCollapse()" [ngClass]="{'visible': visibleErrorCauses}"
                class="error_causes_btn">{{'payment_error_causes' | translate}}
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.19168 6.5896L8.09168 11.5042C8.15002 11.5625 8.21321 11.6039 8.28127 11.6284C8.34932 11.6529 8.42224 11.665 8.50002 11.6646C8.57779 11.6646 8.65071 11.6524 8.71877 11.6279C8.78682 11.6034 8.85002 11.5621 8.90835 11.5042L13.8229 6.5896C13.959 6.45349 14.0271 6.28335 14.0271 6.07919C14.0271 5.87502 13.9542 5.70002 13.8083 5.55419C13.6625 5.40835 13.4924 5.33544 13.2979 5.33544C13.1035 5.33544 12.9333 5.40835 12.7875 5.55419L8.50002 9.84169L4.21252 5.55419C4.07641 5.41808 3.9086 5.35002 3.7091 5.35002C3.5096 5.35002 3.33713 5.42294 3.19168 5.56877C3.04585 5.7146 2.97293 5.88474 2.97293 6.07919C2.97293 6.27363 3.04585 6.44377 3.19168 6.5896Z"
              fill="#60D25E"/>
          </svg>
        </button>

        <div class="error_causes_collapse" [ngClass]="{'visible': visibleErrorCauses}">
          <ol>
            <li>{{'payment_error_cause_1' | translate}}</li>
            <li>{{'payment_error_cause_2' | translate}}</li>
            <li>{{'payment_error_cause_3' | translate}}</li>
          </ol>
        </div>
      </div>
      <a *ngIf="additionalData.restaurantId && additionalData.workerId" href="/tip/{{additionalData.restaurantId}}/{{additionalData.workerId}}" class="error_repeat_btn">{{'payment_error_repeat' | translate}}</a>
      <button (click)="toggleReportErrorModel()"
              class="error_report">{{'payment_error_send_error' | translate}}</button>
    </div>

    <div *ngIf="paymentErrorNotFound" class="error">
      <svg class="error_svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM28.6 28.6C28.415 28.7854 28.1952 28.9325 27.9532 29.0329C27.7113 29.1332 27.4519 29.1849 27.19 29.1849C26.9281 29.1849 26.6687 29.1332 26.4268 29.0329C26.1848 28.9325 25.965 28.7854 25.78 28.6L20 22.82L14.22 28.6C13.846 28.974 13.3389 29.184 12.81 29.184C12.2811 29.184 11.774 28.974 11.4 28.6C11.026 28.226 10.816 27.7189 10.816 27.19C10.816 26.9281 10.8675 26.6688 10.9677 26.4269C11.068 26.185 11.2148 25.9652 11.4 25.78L17.18 20L11.4 14.22C11.026 13.846 10.816 13.3389 10.816 12.81C10.816 12.2811 11.026 11.774 11.4 11.4C11.774 11.026 12.2811 10.816 12.81 10.816C13.3389 10.816 13.846 11.026 14.22 11.4L20 17.18L25.78 11.4C25.9652 11.2148 26.185 11.068 26.4269 10.9677C26.6688 10.8675 26.9281 10.816 27.19 10.816C27.4519 10.816 27.7112 10.8675 27.9531 10.9677C28.195 11.068 28.4148 11.2148 28.6 11.4C28.7852 11.5852 28.932 11.805 29.0323 12.0469C29.1325 12.2888 29.184 12.5481 29.184 12.81C29.184 13.0719 29.1325 13.3312 29.0323 13.5731C28.932 13.815 28.7852 14.0348 28.6 14.22L22.82 20L28.6 25.78C29.36 26.54 29.36 27.82 28.6 28.6Z"
                fill="#D82020"/>
      </svg>
      <p class="error_message">{{'payment_error_not_found' | translate}}</p>
    </div>

    <div class="yandex_ads">
      <div id="adfox_16760668925826209"></div>
    </div>
  </div>

</div>

<div class="report_modal_wrapper" *ngIf="visibleReportErrorModel" (click)="toggleReportErrorModel()">
  <div class="report_modal" (click)="$event.stopPropagation()">
    <button (click)="toggleReportErrorModel()" class="report_modal_close">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.52 6L6 18M6.48 6L18 18" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
    <form [formGroup]="reportErrorForm" (ngSubmit)="reportError()">
      <div class="form-group">
        <label for="name">{{'payment_error_send_error_name' | translate}}</label>
        <input type="text" id="name" name="name" class="form-control" required formControlName="name">
        <div
          *ngIf="reportErrorForm.get('name').invalid && (reportErrorForm.get('name').dirty || reportErrorForm.get('name').touched)"
          class="text-danger">
          <div
            *ngIf="reportErrorForm.get('name').errors.required">{{'payment_error_send_error_name' | translate}} {{'is_required' | translate}}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="message">{{'payment_error_send_error_message' | translate}}</label>
        <textarea id="message" name="message" class="form-control" required formControlName="message"></textarea>
        <div
          *ngIf="reportErrorForm.get('message').invalid && (reportErrorForm.get('message').dirty || reportErrorForm.get('message').touched)"
          class="text-danger">
          <div
            *ngIf="reportErrorForm.get('message').errors.required">{{'payment_error_send_error_message' | translate}} {{'is_required' | translate}}</div>
        </div>
      </div>
      <p class="success_message" *ngIf="visibleReportErrorSuccessMessage">{{'payment_error_send_error_suc' | translate}}</p>
      <button type="submit" class="btn-submit"
              [disabled]="reportErrorForm.invalid">{{'check_send' | translate}}</button>
    </form>
  </div>
</div>






