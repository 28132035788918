import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../dialog/dialog.component';
import {PayService} from '../pay.service';
import {switchMap, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, of, ReplaySubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {PaymentMethods} from '../interfaces/payment-methods.interface';
import {PaymentRequest, loadStripe, Stripe, PaymentIntent, PaymentRequestPaymentMethodEvent} from '@stripe/stripe-js';

@Component({
    selector: 'app-main-form',
    templateUrl: './main-form.component.html',
    styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit, OnDestroy {
    amounts = [300, 500, 1000, 2000];
    reviews = {
        goodAtmosphere: false,
        deliciousFood: false,
        goodService: false
    };
    rate = {
        USD: 403,
        RUB: 4.45
    };
    fee = 6.5;
    stripeFeeAmount = 200;
    reviewControl = new UntypedFormControl('');
    selectedAmount: number = this.amounts[2];
    workerId = null;
    restaurantId = null;
    worker = null;
    imagePath = environment.imageUrl;
    goalPercent = 0;
    amountControl = new UntypedFormControl(this.selectedAmount, [Validators.required, Validators.min(300), Validators.max(10000)]);
    feeControl = new UntypedFormControl(true);
    unsubscribe = new ReplaySubject();
    grade = null;
    showGradeAnimation = false;
    selectedLanguage = localStorage.getItem('lang') || 'hy';
    langControl = new UntypedFormControl(this.selectedLanguage);
    openLangs = false;
    commission;
    selectedPaymentMethod = 'card';
    iDramForm: UntypedFormGroup;
    readonly paymentMethods = PaymentMethods;
    isShown = false;
    isShownCard = false;
    isShownSystem = false;
    isShownStripe = false;
    amountFee: number;
    feeCalc: number;
    reviewIconsPositive: Array<any>;
    reviewIconsNegative: Array<any>;
    reviewIconsSelected = '';

    paymentRequestOptions = {
        country: 'US',
        currency: 'amd',
        total: {
            label: 'Send a tip',
            amount: Number(`${this.amountControl.value}00`),
        },
        requestPayerName: false,
        requestPayerEmail: false,
    };
    stripe: Stripe;
    paymentRequest: PaymentRequest;

    @ViewChild('idramForm') idramForm;

    ameriaConfirmText: string;

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private payService: PayService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private snackBar: MatSnackBar,
        private tr: TranslateService,
        // public constants: Constants,
    ) {
    }

    ngOnInit() {
        this.workerId = this.route.snapshot.params.id;
        this.restaurantId = this.route.snapshot.params.restaurantId;
        if (this.workerId && this.restaurantId) {
            this.payService.checkWorkerInfo(this.restaurantId, this.workerId)
                .pipe(take(1))
                .subscribe(async (res: any) => {
                        this.worker = res.result;
                        this.amounts = this.worker.settings.suggestedTips;
                        this.selectedAmount = this.amounts[2];
                        // this.feeCalc = Math.ceil(this.selectedAmount * this.fee / 100);
                        // this.amountFee = Number(this.selectedAmount) + Number(this.feeCalc);
                        this.commission = this.worker.settings.commission;
                        this.fee = res.result.settings.commissionFee;
                        this.feeChange();
                        this.amountControl = new UntypedFormControl(this.selectedAmount,
                            [Validators.required, Validators.min(this.worker.settings.minTips),
                                Validators.max(this.worker.settings.maxTips)]);
                        if (this.worker.goals && this.worker.goals[0]) {
                            this.goalPercent = Math.ceil((this.worker.goals[0].collectedAmount / this.worker.goals[0].amount) * 100);
                        }
                        this.buildIDramForm();
                        this.reviewIconsPositive = this.worker.reviewIcons.filter(e => e.positive === true);
                        this.reviewIconsNegative = this.worker.reviewIcons.filter(e => e.positive === false);
                        await this.initStripe();
                        this.updateStripeAmount();
                    }, _ => this.router.navigate(['/not-found'])
                );
        } else {
            this.router.navigate(['/not-found']);
        }
        this.payService.getRate().subscribe((rateRes: any) => {
            rateRes.result.forEach(element => {
                if (element.currency === 'USD') {
                    this.rate.USD = element.purchase;
                } else {
                    this.rate.RUB = element.purchase;
                }
            });
        });
    }

    buildIDramForm() {
        this.iDramForm = this.formBuilder.group({
            EDP_LANGUAGE: [this.selectedLanguage === 'hy' ? 'AM' : this.selectedLanguage.toUpperCase()],
            EDP_REC_ACCOUNT: ['110001811'],
            EDP_DESCRIPTION: ['Send a tip'],
            EDP_AMOUNT: [this.selectedAmount],
            EDP_BILL_NO: [],
            EDP_EMAIL: [''],
            EDP_URL_SUCCESS: [environment.idramSuccessURL],
            EDP_URL_FAIL: [environment.idramFailURL],
            EDP_URL_CONFIRM: [environment.idramCheckURL]
        });
    }

    selectLang(lang) {
        this.openLangs = !this.openLangs;
        this.selectedLanguage = lang;
        this.tr.use(this.selectedLanguage);
        localStorage.setItem('lang', this.selectedLanguage);
    }

    setRate(e) {
        if (this.grade === e) {
            this.grade = null;
            return;
        }
        this.grade = e;
        this.reviewIconsSelected = '';
    }

    openDialog(type: any, paymentType: PaymentMethods): void {
        if (this.grade === 0) {
            this.showGradeAnimation = true;
            return;
        }
        const tip = {
            workerId: this.workerId,
            restaurantId: this.restaurantId,
            amount: this.amountControl.value,
            includeTransactions: this.feeControl.value,
            ...this.reviews, review: this.reviewControl.value + ' --- ' + this.reviewIconsSelected, grade: this.grade,
            paymentGateWay: paymentType,
            rate: 1
        };
        this.payService.worker = this.worker;
        this.payService.tip = tip;
        if (paymentType === PaymentMethods.IDRAM) {
            if (this.feeControl.value) {
                this.iDramForm.controls.EDP_AMOUNT.setValue(Number(tip.amount) +
                    Math.ceil(tip.amount * this.fee / 100));
            }
            this.payService.leaveTip(tip)
                .pipe(take(1))
                .subscribe((res: any) => {
                    this.iDramForm.controls.EDP_BILL_NO.setValue(res.result.orderNumber);
                    this.idramForm.nativeElement.submit();
                });
            // tslint:disable-next-line:max-line-length
        } else if ([PaymentMethods.YOOMONEY, PaymentMethods.YOOCARD, PaymentMethods.SBERBANK, PaymentMethods.TINKOFFBANK, PaymentMethods.SBP].includes(paymentType)) {
            // tip.amount = Number(tip.amount / this.rate.RUB);
            tip.rate = this.rate.RUB;
            this.payService.leaveTip(tip)
                .pipe(take(1))
                .subscribe((res: any) => {
                    location.href = res.result.payment.confirmation.confirmation_url;
                });
        } else if (paymentType === PaymentMethods.INECOPAY) {
            this.payService.leaveTip(tip)
                .pipe(take(1))
                .subscribe((res: any) => {
                    location.href = `${environment.inecopayQRURL}?clientId=${res.result.payment.entity.paymentInformationId}&qrType=0`;
                });
        } else if (paymentType === PaymentMethods.AMERIA) {
            this.tr.get('ameriaConfirmUpdate').subscribe((translated: string) => {
                this.ameriaConfirmText = translated;  // Store the translated string
            });

            if (confirm(this.ameriaConfirmText) === true) {
                this.payService.leaveTip(tip)
                    .pipe(take(1))
                    .subscribe((res: any) => {
                        location.href = res.result.payment;
                    });
            }
        } else {
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '250px',
                data: {type, data: tip}
            });
            dialogRef.afterClosed().subscribe(result => {
                this.payService.worker = null;
                this.payService.tip = null;
                if (result === 'err') {
                    this.snackBar.open('Something went wrong', '', {
                        duration: 2000,
                        panelClass: 'error',
                    });
                }
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    returnButtonLabelDependingOnPaymentMethod() {
        let buttonLabel = '';
        switch (this.selectedPaymentMethod) {
            case PaymentMethods.CARD:
                buttonLabel = 'pay_with_credit';
                break;
            case PaymentMethods.IDRAM:
                buttonLabel = 'pay_with_idram';
                break;
        }
        return buttonLabel;
    }

    setAmount(amount: number) {
        this.selectedAmount = amount;
        this.amountControl.setValue(this.selectedAmount, {emitEvent: false});
        this.iDramForm.controls.EDP_AMOUNT.setValue(amount);
        this.feeChange();
        this.updateStripeAmount();
    }

    feeChange() {
        if (this.commission) {
            if (this.feeControl.value) {
                this.feeCalc = Math.ceil(this.selectedAmount * this.fee / 100);
            } else {
                this.feeCalc = 0;
            }
            this.amountFee = Number(this.selectedAmount) + Number(this.feeCalc);
        } else {
            this.feeCalc = 0;
            this.amountFee = Number(this.selectedAmount);
            this.feeControl.setValue(false);
        }
        this.updateStripeAmount();
    }

    toggleModal() {
        this.isShown = !this.isShown;
        setTimeout(() => {
            this.isShownCard = false;
            this.isShownSystem = false;
            this.isShownStripe = false;
        }, 500);
    }

    toggleCard() {
        this.isShownCard = !this.isShownCard;
    }

    toggleSystem() {
        this.isShownSystem = !this.isShownSystem;
    }

    toggleStripe() {
        this.isShownStripe = !this.isShownStripe;
    }

    reviewIconHandler(text: string) {
        if (this.reviewIconsSelected === '') {
            this.reviewIconsSelected = text;
        } else {
            if (this.reviewIconsSelected.includes(`, ${text}`)) {
                this.reviewIconsSelected = this.reviewIconsSelected.replace(`, ${text}`, '');
            } else if (this.reviewIconsSelected.includes(text)) {
                this.reviewIconsSelected = this.reviewIconsSelected.replace(text, '');
            } else {
                this.reviewIconsSelected += `, ${text}`;
            }
        }
        // console.log(this.reviewIconsSelected);
    }

    async initStripe() {
        this.stripe = await loadStripe(environment.stripePublicKey);
        this.paymentRequest = this.stripe.paymentRequest(this.paymentRequestOptions);
        const prButton = this.stripe.elements().create('paymentRequestButton', {
            paymentRequest: this.paymentRequest,
            style: {
                paymentRequestButton: {
                    height: '48px',
                },
            },
        });
        await (async () => {
            // Check the availability of the Payment Request API first.
            const result = await this.paymentRequest.canMakePayment();
            if (result.applePay || result.googlePay) {
                prButton.mount('#payment-request-button');
                if (result.googlePay) {
                    const img = document.querySelector('#payment-request-button-disabled img') as HTMLImageElement;
                    img.src = 'assets/pay/google_pay.svg';
                }
            } else {
                document.getElementById('payment-request-button').style.display = 'none';
                document.getElementById('payment-request-button-disabled').style.display = 'none';
            }
        })();
        this.paymentRequest.on('paymentmethod', (ev) => this.onPaymentMethod(ev));
    }

    updateStripeAmount() {
        if (this.paymentRequest) {
            if (this.feeControl.value) {
                this.paymentRequestOptions.total.amount = Number(`${this.stripeFeeAmount + Number(this.selectedAmount) + Math.ceil(this.selectedAmount * this.fee / 100)}00`);
            } else {
                this.paymentRequestOptions.total.amount = Number(`${this.stripeFeeAmount + Number(this.selectedAmount)}00`);
            }
            this.paymentRequest.update({
                total: {
                    amount: this.paymentRequestOptions.total.amount,
                    label: 'Leave a tip'
                }
            });
        }
    }

    onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
        this.createPaymentIntent()
            .pipe(
                switchMap(async (pi) => {
                    const {paymentIntent, error: confirmError} = await this.stripe.confirmCardPayment(
                        pi.client_secret,
                        {payment_method: ev.paymentMethod.id},
                        {handleActions: false}
                    );
                    const body = {
                        type: 'notification',
                        event: 'fail',
                        object: {
                            id: pi.id,
                            status: 'fail',
                            paid: false
                        },
                    };
                    if (confirmError) {
                        body.event = 'fail';
                        body.object.status = 'fail';
                        body.object.paid = false;
                        ev.complete('fail');
                    } else {
                        ev.complete('success');
                        if (paymentIntent.status === 'requires_action') {
                            const {error} = await this.stripe.confirmCardPayment(pi.client_secret);
                            if (error) {
                                body.event = 'fail';
                                body.object.status = 'fail';
                                body.object.paid = false;
                            } else {
                                body.event = 'success';
                                body.object.status = 'success';
                                body.object.paid = true;
                            }
                        } else {
                            body.event = 'success';
                            body.object.status = 'success';
                            body.object.paid = true;
                        }
                    }
                    this.payService.changeStripeStatus(body).pipe(take(1))
                        .subscribe((res: any) => {
                            window.location.href = `/payment?paymentId=${pi.id}&apiGateway=stripe`;
                        });
                })
            )
            .subscribe((result) => {
                // if (result.error) {
                // } else {
                // }
            });
    }

    createPaymentIntent(): Observable<PaymentIntent> {
        const tip = {
            workerId: this.workerId,
            restaurantId: this.restaurantId,
            amount: this.amountControl.value,
            includeTransactions: this.feeControl.value,
            ...this.reviews,
            review: this.reviewControl.value + ' --- ' + this.reviewIconsSelected,
            grade: this.grade,
            paymentGateWay: PaymentMethods.STRIPE,
            rate: 1
        };
        return this.payService.leaveTip<PaymentIntent>(tip);
    }

}
