import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {PayService} from '../pay.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-restaurant',
    templateUrl: './restaurant.component.html',
    styleUrls: ['./restaurant.component.scss']
})

export class RestaurantComponent implements OnInit, OnDestroy {

    constructor(private route: ActivatedRoute, private router: Router, private payService: PayService, private tr: TranslateService) {
    }
    imagePath = environment.imageUrl;
    selectedLanguage = localStorage.getItem('lang') || 'hy';
    restaurantId = null;
    restaurant = false;
    workers = [];

    langListActive = false;

    protected readonly Math = Math;

    ngOnInit() {
        this.restaurantId = this.route.snapshot.params.restaurantId;
        if (this.restaurantId) {
            this.payService.checkRestaurantInfo(this.restaurantId)
                .pipe(take(1))
                .subscribe(async (res: any) => {
                        if (res.result[0]) {
                            this.workers = res.result[0].workers;
                            this.restaurant = res.result[0];
                        } else {
                            this.restaurant = false;
                            void this.router.navigate(['/not-found']);
                        }
                    }, _ => this.router.navigate(['/not-found'])
                );
        } else {
            void this.router.navigate(['/not-found']);
        }
    }

    ngOnDestroy() {
    }

    selectLang(lang: string) {
        this.selectedLanguage = lang;
        this.tr.use(this.selectedLanguage);
        localStorage.setItem('lang', this.selectedLanguage);
        this.langListActive = false;
    }

    toggleLangList() {
        this.langListActive = !this.langListActive;
    }
}
