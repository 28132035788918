<div *ngIf="type==='currency'">
  <p class="header">Choose currency</p>
  <div class="currencies">
    <div class="currency" [class.checked]="checked === 'ru'" (click)="checked = 'ru'">
      <div>
        <img src="assets/ru.svg" alt="">
        <p>Russian ruble</p>
      </div>
      <p>₽</p>
    </div>
    <div class="currency" [class.checked]="checked === 'us'" (click)="checked = 'us'">
      <div>
        <img src="assets/us.svg" alt="">
        <p>USA dollar</p>
      </div>
      <p>$</p>
    </div>
    <div class="currency" [class.checked]="checked === 'am'" (click)="checked = 'am'">
      <div>
        <img src="assets/am.svg" alt="">
        <p>Armenian dram</p>
      </div>
      <p>֏</p>
    </div>
  </div>
  <div class="buttons">
    <button class="cancel-button" (click)="cancel()">Cancel</button>
    <button class="tip-button" (click)="save()">Save</button>

  </div>
</div>

<div *ngIf="type === 'pay'">
  <p class="header">Your tip is on the way ...</p>
  <div class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>

</div>


<div *ngIf="type === 'error'" class="error">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
    <desc>Created with Fabric.js 1.7.22</desc>
    <defs>
    </defs>
    <g transform="translate(128 128) scale(0.72 0.72)" style="">
      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
      <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
      <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
      <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    </g>
    </g>
    </svg>
    <h4>Something went wrong, please contact to your bank</h4>
    <h4>Ինչ-որ բան սխալ է, դիմեք ձեր բանկին</h4>
    <h4>Что-то пошло не так, обратитесь в свой банк</h4>
  <div class="buttons">
    <button class="tip-button" mat-dialog-close>Ok</button>
  </div>
</div>

<div *ngIf="type === 'success'" class="error">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
    <desc>Created with Fabric.js 1.7.22</desc>
    <defs>
    </defs>
    <g transform="translate(128 128) scale(0.72 0.72)" style="">
      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
      <circle cx="45" cy="45" r="45" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(40,201,55); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
      <path d="M 38.478 64.5 c -0.01 0 -0.02 0 -0.029 0 c -1.3 -0.009 -2.533 -0.579 -3.381 -1.563 L 21.59 47.284 c -1.622 -1.883 -1.41 -4.725 0.474 -6.347 c 1.884 -1.621 4.725 -1.409 6.347 0.474 l 10.112 11.744 L 61.629 27.02 c 1.645 -1.862 4.489 -2.037 6.352 -0.391 c 1.862 1.646 2.037 4.49 0.391 6.352 l -26.521 30 C 40.995 63.947 39.767 64.5 38.478 64.5 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    </g>
    </g>
    </svg>
    <h4>Your tip was successfully sent</h4>
    <h4>Թեյավճարը հաջողությամբ փոխանցվել է</h4>
    <h4>Чаевые успешно отправлены</h4>
  <div class="buttons">
    <button class="tip-button" mat-dialog-close>{{'done' | translate}}</button>
  </div>
</div>