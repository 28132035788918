import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PayService {
    private backendUrl = environment.backendUrl;
    worker = null;
    tip = null;

    constructor(
        private http: HttpClient
    ) {
    }

    checkRestaurantInfo(restaurantId: any) {
        /*const url = `${this.backendUrl}/admin/${restaurantId}/checkout/full/list`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ads`);
        return this.http.get(url, { headers });*/
        return this.http.get(`${this.backendUrl}/tips/restaurant/${restaurantId}/checkout/list`);
    }

    checkWorkerInfo(restaurantId: any, id: any) {
        return this.http.get(`${this.backendUrl}/tips/restaurant/${restaurantId}/worker/${id}`);
    }

    leaveTip<T>(body: any) {
        return this.http.post<T>(`${this.backendUrl}/tips/leave`, body);
    }

    changeStripeStatus(body: any) {
        return this.http.post(`${this.backendUrl}/tips/stripe/result`, body);
    }

    emailPreSend(body: { email: any; }) {
        return this.http.post(`${this.backendUrl}/tips/receipt/presend`, body);
    }

    emailSend(body: { email: any; key: any; }) {
        return this.http.post(`${this.backendUrl}/tips/receipt/send`, body);
    }

    emailSupport(body: { name: any; surname: any; email: string; theme: string; message: any; }) {
        return this.http.post(`${this.backendUrl}/users/email/support/v1`, body);
    }

    getTransactionById(id: any, apiGateway: string | number | boolean, ameriaId: string) {
        const queryParams = new HttpParams().append('apiGateway', apiGateway).append('ameriaId', ameriaId);
        return this.http.get(`${this.backendUrl}/tips/transactions/${id}`, {params: queryParams});
    }

    getRate() {
        return this.http.get(`${this.backendUrl}/rates/exchange`);
    }

    amazon(url: any, body: any) {
        return this.http.put(url, body, {
            headers: {
                'Content-Type': 'application/pdf',
            },
        });
    }
}
