import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {DialogComponent} from '../dialog/dialog.component';
import {PayService} from '../pay.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-thanks',
    templateUrl: './thanks.component.html',
    styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
    stars = 4;
    transaction = null;
    additionalData = null;
    transactionId = null;
    paymentError = false;
    paymentErrorNotFound = false;
    visibleCheckForm = false;
    visibleErrorCauses = false;
    visibleReportErrorModel = false;
    visibleReportErrorSuccessMessage = false;
    visibleCheckSendSuccessMessage = false;
    visibleCheckSendPending = false;
    checkSendForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
    reportErrorForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        message: new UntypedFormControl('', [Validators.required]),
    });


    constructor(
        private payService: PayService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        // EDP_BILL_NO
        let apiGateway: string;
        let ameriaId: string;
        if (this.route.snapshot.queryParams.orderId) {
            this.transactionId = this.route.snapshot.queryParams.orderId;
            apiGateway = 'bank';
        } else if (this.route.snapshot.queryParams.EDP_BILL_NO) {
            this.transactionId = this.route.snapshot.queryParams.EDP_BILL_NO;
            apiGateway = 'idram';
        } else if (this.route.snapshot.queryParams.transactionId) {
            this.transactionId = this.route.snapshot.queryParams.transactionId;
            ameriaId = this.route.snapshot.queryParams.paymentId;
            apiGateway = 'ameria';
        } else if (this.route.snapshot.queryParams.paymentId) {
            this.transactionId = this.route.snapshot.queryParams.paymentId;
            apiGateway = 'stripe';
        }
        if (this.route.snapshot.queryParams.apiGateway) {
            apiGateway = this.route.snapshot.queryParams.apiGateway;
        }
        if (this.transactionId) {
            this.payService.getTransactionById(this.transactionId, apiGateway, ameriaId)
                .pipe(take(1))
                .subscribe((res: any) => {
                    // const dialogRef = this.dialog.open(DialogComponent, {
                    //   data: { type: 'success', key: 'your_tip_was_sent_to' }
                    // });
                    this.transaction = res.result;
                }, err => {
                    if (err.error.errorName === 'NOT_FOUND_ERROR') {
                        this.paymentErrorNotFound = true;
                    }
                    this.paymentError = true;
                    this.additionalData = err.error.additionalData;
                    // this.router.navigate(['/']);
                    // const dialogRef = this.dialog.open(DialogComponent, {
                    //   data: { type: 'error', key: 'payment_error' }
                    // });
                });
        } else {
            this.router.navigate(['/']);
        }
    }


    done() {
        this.router.navigate(['/']);
    }

    toggleCheckFormCollapse(): void {
        this.visibleCheckForm = !this.visibleCheckForm;
    }

    toggleErrorCausesCollapse(): void {
        this.visibleErrorCauses = !this.visibleErrorCauses;
    }

    toggleReportErrorModel(): void {
        this.visibleReportErrorModel = !this.visibleReportErrorModel;
        this.visibleReportErrorSuccessMessage = false;
    }

    async checkSend() {
        this.visibleCheckSendPending = true;
        const pdtHtmlELem = document.getElementById('check-section');
        const email = this.checkSendForm.value.email;
        const emailPre = this.payService.emailPreSend({
            email
        }).pipe().subscribe(async (res: any) => {
            this.checkSendForm.reset();
            const {url, key} = res;
            html2canvas(pdtHtmlELem, {
                logging: true,
                useCORS: true,
            }).then(async (canvas) => {
                const imgWidth = 208;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                const pdfAsString = pdf.output('arraybuffer');
                const aws = await this.payService.amazon(url, pdfAsString).pipe().subscribe(async (res2: any) => {
                    // if (res2.status !== 200) {
                    //   throw new Error('Something went wrong');
                    // }
                    const emailSend = this.payService.emailSend({
                        email,
                        key
                    }).pipe().subscribe((res3) => {
                        this.visibleCheckSendSuccessMessage = true;
                        this.visibleCheckSendPending = false;
                    });
                });
            });
        });
    }

    async reportError() {
        const restaurant = this.reportErrorForm.value.name;
        const message = this.reportErrorForm.value.message;
        const result = await this.payService.emailSupport({
            name: restaurant,
            surname: restaurant,
            email: 'confirm@tips.am',
            theme: 'issue with payment',
            message,
        }).pipe().subscribe((res: any) => {
            if (res.message === 'Success') {
                this.reportErrorForm.reset();
                this.visibleReportErrorSuccessMessage = true;
            }
        });
    }

    downloadPdf() {
        const input = document.getElementById('check-section');
        html2pdf(input, {
            margin: 1,
            letterRendering: 1,
            useCORS: true,
            filename: `check-${this.transactionId}.pdf`,
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {dpi: 192, letterRendering: true},
            jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
        });
    }
}
