import {Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mathCeil'})
export class MathCeilPipe implements PipeTransform {
  strBeginning: number = 0;
  strEnding: number = 0;

  constructor() {}

  transform(value: any) {
    if (value) {
        return Math.ceil(+value);
    }
  }
}
