import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tips-payment';
  constructor(tr: TranslateService){
    tr.setDefaultLang(localStorage.getItem('lang') || 'hy');
  }
}
